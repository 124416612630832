.hero-container {
  position: relative;
  width: 100%;
  height: 900px;
  overflow: hidden;
}

.hero-image {
  position: relative;
  height: 550px;
  overflow: hidden;
}

.hero-image .slick-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.hero-image .slick-dots li button:before {
  font-size: 12px;
  color: white;
  opacity: 0.5;
}

.hero-image .slick-dots li.slick-active button:before {
  opacity: 1;
}

.hero-image .slick-dots li {
  margin: 0 5px;
}

.hero-slider-image {
  width: 100%;
  height: 550px;
  object-fit: cover;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.hero-text {
  z-index: 1;
}

.hero-image .slick-slide img {
  z-index: 0;
}

.hero-text h1 {
  margin-bottom: 3%;
}

.hero-text p {
  margin-bottom: 20px;
}

.services-container {
  margin-top: 20px;
  text-align: center;
}


.card-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card {
  margin-bottom: 20px;
}

.prof-card {
  width: '100%';
  max-width: '18rem';
  border-radius: 50px !important;
  overflow: hidden;
}

@media (max-width: 576px) {

  .hero-text {
    width: 90%;
    transform: translate(-50%, -70%);
  }

  .hero-text h1 {
    font-size: 1.75rem;
  }

  .hero-text p {
    font-size: 1rem;
  }
}

@media (min-width: 577px) and (max-width: 1376px) {

  .hero-text {
    transform: translate(-50%, -70%);
  }

  .hero-text h1 {
    font-size: 3.2rem;
    white-space: nowrap;
  }

  .hero-text p {
    font-size: 1rem;
  }
}

@media (max-width: 3076px) {
  .card-custom {
      width: 25rem !important;
      height: 25rem !important;
      border-radius: 50px !important;
      overflow: hidden;
  }
  
  .card-img-top {
      height: 350px;
  }
}

.card-body {
  background: #48cae4;
}

.follow-button {
  background-color: #ffd48f;
  color: black;
  border: white;
  font-weight: bold;
}

.button-custom {
  background-color: #fcbf49;
  color: black;
  font-weight: bold;
  border-radius: 10px;
  border: white;
  font-size: large;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.button-custom:hover {
  background-color: #87CEEB;
  border-color: #4682B4;
}

.card-professional {
  object-fit: contain;
  height: 200px;
}
