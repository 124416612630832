.custom-button {
    background-color: #dd2d4a; 
    color: white;
    font-weight: bold;
    border-radius: 10px;
    border: white;
  }

Nav Link {
    font-size: large;
}

.nav-buttons {
  color: #219ebc !important;
  font-weight: bold;
}
